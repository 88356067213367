var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.userData ? _c('div', {}, [_c('b-nav-item-dropdown', {
    staticClass: "dropdown-notification mr-25",
    attrs: {
      "menu-class": "dropdown-menu-media dropdown-menu-right",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "text-body",
          attrs: {
            "badge": "6",
            "badge-classes": "bg-danger",
            "icon": "BellIcon",
            "size": "21"
          }
        })];
      },
      proxy: true
    }], null, false, 1100150622)
  }, [_c('li', {
    staticClass: "dropdown-menu-header"
  }, [_c('div', {
    staticClass: "dropdown-header d-flex"
  }, [_c('h4', {
    staticClass: "notification-title mb-0 mr-auto"
  }, [_vm._v("Notifications")]), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v("6 New")])], 1)]), _vm._m(0), _c('li', {
    staticClass: "dropdown-menu-footer"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "block": ""
    }
  }, [_vm._v(" Read all notifications ")])], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container media-list scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "li"
    }
  }, [_vm._l(_vm.notifications, function (notification) {
    return _c('b-link', {
      key: notification.subtitle
    }, [_c('b-media', {
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [_c('b-avatar', {
            attrs: {
              "size": "32",
              "src": notification.avatar,
              "text": notification.avatar,
              "variant": notification.type
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('p', {
      staticClass: "media-heading"
    }, [_c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(notification.title) + " ")])]), _c('small', {
      staticClass: "notification-text"
    }, [_vm._v(_vm._s(notification.subtitle))])])], 1);
  }), _c('div', {
    staticClass: "media d-flex align-items-center"
  }, [_c('h6', {
    staticClass: "font-weight-bolder mr-auto mb-0"
  }, [_vm._v("System Notifications")]), _c('b-form-checkbox', {
    attrs: {
      "checked": true,
      "switch": ""
    }
  })], 1), _vm._l(_vm.systemNotifications, function (notification) {
    return _c('b-link', {
      key: notification.subtitle
    }, [_c('b-media', {
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [_c('b-avatar', {
            attrs: {
              "size": "32",
              "variant": notification.type
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": notification.icon
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('p', {
      staticClass: "media-heading"
    }, [_c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(notification.title) + " ")])]), _c('small', {
      staticClass: "notification-text"
    }, [_vm._v(_vm._s(notification.subtitle))])])], 1);
  })], 2);
}]

export { render, staticRenderFns }