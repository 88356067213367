var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cws-container cws-sidebar-right"
  }, [_c('div', {
    staticClass: "cws-content"
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "cws-sidebar"
  }, [_vm._t("sidebar")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }